import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import eventEmitter from "../Helper/eventEmitter";
import RoomTooltips from "./RoomTooltips";

export default function RoomTable({
  reveal,
  tooltips,
  count,
  user,
  isAdmin,
}: any) {
  const [pick, setPick] = useState(false);
  const [toolview, setToolview] = useState(true);

  useEffect(() => {
    function havepick(data: any) {
      if (data === 0 || data) {
        setPick(true);
      } else {
        setPick(false);
      }
    }

    // function listenerfunc(e: any) {
    //   e = e || window.event;
    //   if (e.keyCode === 32) {
    //     document.getElementById("changeconditionbtn")?.click();
    //   }
    // }

    eventEmitter.on("editstatuspick", havepick);
    // document.addEventListener("keydown", listenerfunc);

    return () => {
      eventEmitter.off("editstatuspick", havepick);
      // document.removeEventListener("keydown", listenerfunc);
    };
  }, []);

  return (
    <div className="rounded-xlg md:w-full py-20 bg-[#F2F2F2] shadow-lg border flex flex-col items-center justify-center md:min-w-[500] w-1/2 mx-auto relative text-sm md:text-base">
      {tooltips === 4 && toolview && (
        <RoomTooltips type="new" closefunc={() => setToolview(false)} />
      )}
      {tooltips === 3 && toolview && (
        <RoomTooltips type="show" closefunc={() => setToolview(false)} />
      )}
      {reveal && count <= 0 ? (
        <motion.button
          id="changeconditionbtn"
          whileHover={{ scale: isAdmin ? 1.1 : 1 }}
          whileTap={{ scale: isAdmin ? 0.9 : 1 }}
          onClick={() => {
            if (isAdmin) {
              eventEmitter.emit("editcondition", "initiate", user);
              setPick(false);
            }
          }}
          className={`px-3 md:px-8 max-w-full py-2 rounded-lg bg-primary text-white ${
            !isAdmin ? "opacity-50 cursor-default" : ""
          }`}
        >
          Start New Vote
        </motion.button>
      ) : reveal && count > 0 ? (
        <p className="text-xl text-primary font-semibold">{count}</p>
      ) : pick && count <= 0 ? (
        <motion.button
          id="changeconditionbtn"
          whileHover={{ scale: isAdmin ? 1.1 : 1 }}
          whileTap={{ scale: isAdmin ? 0.9 : 1 }}
          onClick={() => {
            if (isAdmin) {
              eventEmitter.emit("editcondition", "reveal", user);
            }
          }}
          className={`px-3 md:px-8 max-w-full py-2 rounded-lg bg-primary text-white ${
            !isAdmin ? "opacity-50 cursor-default" : ""
          }`}
        >
          Reveal Card
        </motion.button>
      ) : (
        <p>Pick your card</p>
      )}
    </div>
  );
}
