import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, useState } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { rdb } from "../Firebase";
import makeId from "../Helper/makeId";
import { track } from '@amplitude/analytics-browser';

interface SelectValue {
  value: string;
  label: string;
}

// const socket = io("http://localhost:8080");

export default function Home() {
  const placeholderClass =
    "absolute peer-focus:text-xs peer-focus:top-0 peer-focus:-translate-y-1/2 bg-white peer-focus:z-10 transition-all peer-focus:px-2 peer-focus:font-semibold peer-focus:text-black text-black/50 font-bold -z-10 top-3 left-3 peer-valid:text-xs peer-valid:top-0 peer-valid:-translate-y-1/2 peer-valid:z-10 peer-valid:px-2 peer-valid:font-semibold peer-valid:text-black";

  const selectOptions: SelectValue[] = [
    {
      value: `[0,"0.5",1,2,3,5,8,13,21,34,55,89,"?"]`,
      label: "Fibonacci (0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, ?)",
    },
    {
      value: `["XS", "S", "M", "L", "XL", "XXL"]`,
      label: "T-Shirts (XS, S, M, L, XL, XXL)",
    },
  ];

  const selectStyle = {
    control: (prov: any) => ({
      ...prov,
      padding: "0.5rem 0.5rem",
      border: "none",
    }),
    option: (prov: any, state: any) => ({
      ...prov,
      backgroundColor: state.isSelected ? "#4A545C" : "white",
    }),
  };

  const [name, setName] = useState<string>("");
  const [voteSystem, setVoteSystem] = useState<SelectValue>(selectOptions[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>("");
  const recaptchaRef = createRef<ReCAPTCHA>();

  const submitFunc = async (e: any) => {
    e.preventDefault();
    if (!loading && name !== "") {
      setLoading(true);
      let newId = makeId(9);
      // socket.emit("newRoom", name, voteSystem.value, newId);
      try {
        const token = await recaptchaRef?.current?.executeAsync();
        if (token) {
          await rdb.ref("rooms/" + newId).set({
            server: newId,
            roomname: name,
            votesystem: voteSystem.value,
            state: "initiate",
            card: JSON.stringify({
              color: null,
              logo: null,
            }),
            user: JSON.stringify([]),
            result: "[]",
            admin: JSON.stringify([localStorage.getItem("thisid")]),
          });
          setLoading(false);
          localStorage.setItem("new", "1");
          console.log("create game")
          // console.log(name)
          track('Create Game', {'voteSystem': voteSystem, 'roomName': name, 'roomId': newId});
          setRedirect(`/${newId}`);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };

  if (redirect.length > 0) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="fixed flex inset-0 w-full h-full">
      <div className="absolute top-0 left-0 right-0 w-full py-5">
        <div className="mx-auto flex space-x-2 items-center px-5 md:px-8">
          <div className="bg-primary w-6 h-8 rounded-md" />
          <p className="text-2xl text-primary font-montserrat font-bold">
            Create game
          </p>
        </div>
      </div>
      <form
        onSubmit={submitFunc}
        className="flex-1 flex flex-col items-center justify-center overflow-auto px-5"
      >
        <p className="text-lg text-secondary text-center">
          Choose a name and a voting system for your game.
        </p>
        <div className="w-full max-w-2xl mt-8 relative">
          <input
            required
            onChange={(e) => setName(e.target.value)}
            className="ring-2 peer ring-primary/40 bg-transparent hover:ring-primary/70 focus:outline-none focus:ring-primary rounded w-full px-3 py-3"
          />
          <p className={placeholderClass}>Game's Name</p>
        </div>
        <div className="w-full max-w-2xl mt-8 relative">
          <Select
            styles={selectStyle}
            onChange={(val: any) => setVoteSystem(val)}
            defaultValue={selectOptions[0]}
            className="border-none ring-2 ring-primary/40 bg-transparent hover:ring-primary/70 focus:outline-none focus:ring-primary rounded w-full"
            options={selectOptions}
          />
          <p className="absolute font-semibold left-2 bg-white px-2 top-0 text-xs -translate-y-1/2">
            Voting system
          </p>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6Lf2GKoeAAAAAIzpopHmzWHC0uZLGq3yGUMBFJl3"
        />
        <div className="mt-8 w-full max-w-2xl">
          <button className="bg-primary hover:opacity-80 transition-all rounded-md block w-full py-3 font-bold text-white text-lg">
            {loading ? (
              <FontAwesomeIcon spin icon={faCircleNotch} />
            ) : (
              "Create Game"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
