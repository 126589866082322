import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import lightOrDark from "../Helper/lightOrDark";
import makeId from "../Helper/makeId";
import RoomTable from "./RoomTable";

interface RoomBodyParams {
  cardUser: Array<any>;
  condition: String;
  openShareModalFunc: VoidFunction;
  backCardCustom: any;
  tooltips: number;
  result: any;
  isAdmin: boolean;
}

export default function RoomBody({
  cardUser,
  condition,
  openShareModalFunc,
  backCardCustom,
  tooltips,
  result,
  isAdmin,
}: RoomBodyParams) {
  const [count, setCount] = useState(0);
  const [displayData, setDisplayData] = useState(cardUser);

  useEffect(() => {
    const myId = localStorage.getItem("thisid") || makeId(15) + Date.now();
    if (condition === "reveal") {
      setDisplayData(
        result.sort((a: any) => {
          if (a.id === myId) {
            return -1;
          }
          return 0;
        })
      );
    } else {
      setDisplayData(
        cardUser.sort((a: any) => {
          if (a.id === myId) {
            return -1;
          }
          return 0;
        })
      );
    }
  }, [condition, result, cardUser]);

  useEffect(() => {
    if (condition === "reveal") {
      setCount(2);
    } else {
      setCount(1);
    }
  }, [condition]);

  useEffect(() => {
    if (count <= 0) return;

    let countdown = setInterval(() => {
      setCount((p) => p - 1);
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [count]);

  const cardVerticalLeftListRender = (item: any, index: number) => (
    <div
      key={index}
      className={`flip-card my-2 mx-5 flex w-14 h-10 items-center justify-center font-bold text-2xl`}
    >
      <div
        className={`flip-card-inner ${
          condition !== "reveal" || count > 0 ? "onh" : ""
        }`}
      >
        <motion.div
          animate={{ borderColor: backCardCustom?.color || "#1D2934" }}
          className="flip-card-front flex items-center justify-center border-2 border-primary rounded-lg"
        >
          <span className="rotate-90">{item.card}</span>
        </motion.div>
        <motion.div
          animate={{
            borderColor: backCardCustom?.color || "#1D2934",
            backgroundColor: !(item.card || item.card === 0)
              ? "white"
              : backCardCustom?.color || "#1D2934",
            color:
              backCardCustom?.color &&
              lightOrDark(backCardCustom?.color) === "light"
                ? "#1D2934"
                : "white",
          }}
          className="flip-card-back flex items-center justify-center border-2 border-primary rounded-lg text-white bg-primary"
        >
          {!(item.card || item.card === 0) ? (
            <div></div>
          ) : backCardCustom?.logo && backCardCustom?.logo !== "" ? (
            <img
              src={backCardCustom?.logo}
              alt=""
              className="w-24 max-w-[95%] max-h-full rotate-90"
            />
          ) : (
            <span className="rotate-90">?</span>
          )}
        </motion.div>
      </div>
    </div>
  );

  const cardVerticalRightListRender = (item: any, index: number) => (
    <div
      key={index}
      className={`flip-card my-2 mx-5 flex w-14 h-10 items-center justify-center font-bold text-2xl`}
    >
      <div
        className={`flip-card-inner ${
          condition !== "reveal" || count > 0 ? "onh" : ""
        }`}
      >
        <motion.div
          animate={{ borderColor: backCardCustom?.color || "#1D2934" }}
          className="flip-card-front flex items-center justify-center border-2 border-primary rounded-lg"
        >
          <span className="-rotate-90">{item.card}</span>
        </motion.div>
        <motion.div
          animate={{
            borderColor: backCardCustom?.color || "#1D2934",
            backgroundColor: !(item.card || item.card === 0)
              ? "white"
              : backCardCustom?.color || "#1D2934",
            color:
              backCardCustom?.color &&
              lightOrDark(backCardCustom?.color) === "light"
                ? "#1D2934"
                : "white",
          }}
          className="flip-card-back flex items-center justify-center border-2 border-primary rounded-lg text-white bg-primary"
        >
          {!(item.card || item.card === 0) ? (
            <div></div>
          ) : backCardCustom?.logo && backCardCustom?.logo !== "" ? (
            <img
              src={backCardCustom?.logo}
              alt=""
              className="w-24 max-w-[95%] max-h-full -rotate-90"
            />
          ) : (
            <span className="-rotate-90">?</span>
          )}
        </motion.div>
      </div>
    </div>
  );

  const cardHorizontalListRender = (item: any, index: number) => (
    <div key={index} className="flex flex-col items-center">
      <div
        className={`flip-card mx-2 flex w-10 h-14 items-center justify-center font-bold text-2xl`}
      >
        <div
          className={`flip-card-inner ${
            condition !== "reveal" || count > 0 ? "on" : ""
          }`}
        >
          <motion.div
            animate={{ borderColor: backCardCustom?.color || "#1D2934" }}
            className="flip-card-front flex items-center justify-center border-2 border-primary rounded-lg"
          >
            <span>{item.card}</span>
          </motion.div>
          <motion.div
            animate={{
              borderColor: backCardCustom?.color || "#1D2934",
              backgroundColor: !(item.card || item.card === 0)
                ? "white"
                : backCardCustom?.color || "#1D2934",
              color:
                backCardCustom?.color &&
                lightOrDark(backCardCustom?.color) === "light"
                  ? "#1D2934"
                  : "white",
            }}
            className="flip-card-back flex items-center justify-center border-2 border-primary rounded-lg text-white bg-primary"
          >
            {!(item.card || item.card === 0) ? (
              <div></div>
            ) : backCardCustom?.logo && backCardCustom?.logo !== "" ? (
              <img
                src={backCardCustom?.logo}
                alt=""
                className="w-24 max-w-[95%] max-h-full"
              />
            ) : (
              <span className="">?</span>
            )}
          </motion.div>
        </div>
      </div>
      <p className="w-24 text-ellipsis overflow-x-hidden text-center">
        {item.name}
      </p>
    </div>
  );

  return (
    <div className="flex-1 w-full flex flex-col overflow-auto">
      <div className="flex-1 order-1" />
      <div className="flex-1 order-3" />
      <div className="mx-auto px-5 md:px-20 order-2">
        {displayData.length <= 1 && (
          <p className="text-lg text-secondary text-center">
            Ready to plan as a team?{" "}
            <motion.button
              onClick={openShareModalFunc}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="font-bold text-primary"
            >
              Invite players
            </motion.button>
          </p>
        )}

        {/* Up */}
        <div className="hidden md:flex mb-5 justify-center">
          {displayData
            .filter((item, index) => {
              if ((index <= 6 || index >= 15) && index % 2 === 1) {
                return item;
              }
              return null;
            })
            .map(cardHorizontalListRender)}
        </div>
        <div className="pc:hidden flex mb-5 justify-center">
          {displayData
            .filter((item, index) => {
              if (index === 1 || index === 2) {
                return item;
              }
              return null;
            })
            .map(cardHorizontalListRender)}
        </div>
        {/* Mid */}
        <div className="flex">
          {/* Left */}
          <div className="hidden md:flex flex-col justify-center">
            {displayData
              .filter((item, index) => {
                if (index > 6 && index < 15 && index % 2 < 1) {
                  return item;
                }
                return null;
              })
              .map(cardVerticalLeftListRender)}
          </div>
          <div className="pc:hidden flex flex-col justify-center">
            {displayData
              .filter((item, index) => {
                if (index > 3 && index % 2 < 1) {
                  return item;
                }
                return null;
              })
              .map(cardVerticalLeftListRender)}
          </div>
          {/* Table */}
          <RoomTable
            tooltips={tooltips}
            reveal={condition === "reveal"}
            count={count}
            user={cardUser}
            isAdmin={isAdmin}
          />
          {/* Right */}
          <div className="hidden md:flex flex-col justify-center">
            {displayData
              .filter((item, index) => {
                if (index > 6 && index < 15 && index % 2 === 1) {
                  return item;
                }
                return null;
              })
              .map(cardVerticalRightListRender)}
          </div>
          <div className="pc:hidden flex flex-col justify-center">
            {displayData
              .filter((item, index) => {
                if (index > 3 && index % 2 === 1) {
                  return item;
                }
                return null;
              })
              .map(cardVerticalRightListRender)}
          </div>
        </div>
        {/* Down */}
        <div className="hidden md:flex mt-5 justify-center px-28">
          {displayData
            .filter((item, index) => {
              if ((index <= 6 || index >= 15) && index % 2 < 1) {
                return item;
              }
              return null;
            })
            .map(cardHorizontalListRender)}
        </div>
        <div className="pc:hidden flex mt-5 justify-center px-28">
          {displayData
            .filter((item, index) => {
              if (index === 0 || index === 3) {
                return item;
              }
              return null;
            })
            .map(cardHorizontalListRender)}
        </div>
      </div>
    </div>
  );
}
