import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import LandingPage from "./Pages/LandingPage";
import Room from "./Pages/Room";
import "./Firebase";
import makeId from "./Helper/makeId";
import { init, track } from '@amplitude/analytics-browser';
// import { socket, SocketContext } from "./Socket";

function App() {
  const [rerender, setRerender] = useState(0);

  useEffect(() => {
    console.log("app start")
    init("9ab082a9365cbb09d0ca5888bfc4e349");
    track('Start App');
    if (!localStorage.getItem("thisid")) {
      localStorage.setItem("thisid", makeId(15) + Date.now());
      setRerender((p) => p + 1);
    }
  }, [rerender]);

  if (!localStorage.getItem("thisid")) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <p>Setting Up...</p>
      </div>
    );
  }

  return (
    // <SocketContext.Provider value={socket}>
    <div className="font-avenir">
      <div>
        <Toaster />
      </div>
      <Router>
        <Switch>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/create-room" exact>
            <Home />
          </Route>
          <Route path="/:id">
            <Room />
          </Route>
        </Switch>
      </Router>
    </div>
    // </SocketContext.Provider>
  );
}

export default App;
