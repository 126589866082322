import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

export default function RoomShareModal({ closeFunc }: any) {
  const inputComponent = useRef<any>(null);
  const nowlink = window.location.href;

  useEffect(() => {
    inputComponent.current?.select();
    inputComponent.current.setSelectionRange(0, 9999);
  }, []);

  const submitNewName = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputComponent.current.value);
    closeFunc(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-exit
      onClick={(e: any) => {
        if (localStorage.getItem("name") && e?.target?.dataset?.exit) {
          closeFunc();
        }
      }}
      className="fixed top-0 left-0 w-full h-full bg-black/40 z-20"
    >
      <motion.form
        initial={{ opacity: 0, translateY: -100 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: -100 }}
        onSubmit={submitNewName}
        className="py-10 md:py-20 px-8 shadow-2xl w-11/12 max-w-3xl bg-white rounded-[48px] mx-auto mt-52"
      >
        <p className="text-black text-2xl font-bold">Invite Players</p>
        <input
          ref={inputComponent}
          value={nowlink}
          autoFocus
          readOnly
          placeholder="Invitation Link..."
          required
          className="border border-primary rounded-xl block px-3 py-2 w-full mt-16 mb-8"
        />
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="rounded-xl text-center block w-full bg-primary text-white py-3"
        >
          Copy invitation link
        </motion.button>
      </motion.form>
    </motion.div>
  );
}
