import { motion } from "framer-motion";
import React, { useRef } from "react";
import eventEmitter from "../Helper/eventEmitter";

export default function RoomNameModal({ closeFunc }: any) {
  const name = useRef<HTMLInputElement>(null);

  const submitNewName = (e: any) => {
    e.preventDefault();
    if (!localStorage.getItem("name")) {
      eventEmitter.emit("addUser", name?.current?.value.trim() || "guest");
    } else {
      eventEmitter.emit("editName", name?.current?.value.trim() || "guest");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-exit
      onClick={(e: any) => {
        if (localStorage.getItem("name") && e?.target?.dataset?.exit) {
          closeFunc();
        }
      }}
      className="fixed top-0 left-0 w-full h-full bg-black/40 z-20 flex justify-center items-center"
    >
      <motion.form
        initial={{ opacity: 0, translateY: -100 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: -100 }}
        onSubmit={submitNewName}
        className="py-10 md:py-20 px-8 shadow-2xl w-11/12 max-w-3xl bg-white rounded-[48px] mx-auto"
      >
        <p className="text-black text-2xl font-bold">
          Choose your display name
        </p>
        <input
          ref={name}
          id="inputname"
          autoFocus
          placeholder="Your name..."
          required
          className="border border-primary rounded-xl block px-3 py-2 w-full mt-16 mb-8"
        />
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          id="submitname"
          className="rounded-xl text-center block w-full bg-primary text-white py-3"
        >
          Continue to game
        </motion.button>
      </motion.form>
    </motion.div>
  );
}
