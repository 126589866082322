// Import the functions you need from the SDKs you need
import firebase from "firebase";
require("firebase/firestore");
require("firebase/storage");
require("firebase/database");
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtkCiQH93yudVPm2mbvnhjEJo2XT76rjU",
  authDomain: "pokerplanning-a7915.firebaseapp.com",
  projectId: "pokerplanning-a7915",
  storageBucket: "pokerplanning-a7915.appspot.com",
  messagingSenderId: "143798848608",
  appId: "1:143798848608:web:8f22a8c7be11f3301a0705",
};

// Initialize Firebase
// let app;
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

// firebase.firestore().settings({ experimentalForceLongPolling: true });

// export const auth = firebase.auth();
export const rdb = firebase.database();
export const db = firebase.firestore();
export const storage = firebase.storage();
