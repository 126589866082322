import { useParams } from "react-router-dom";
import RoomBody from "../Components/RoomBody";
import RoomFooter from "../Components/RoomFooter";
import RoomHeader from "../Components/RoomHeader";
import { useEffect, useState } from "react";
import eventEmitter from "../Helper/eventEmitter";
import NotFoundRoom from "./NotFoundRoom";
import arraysEqual from "../Helper/arrayEqual";
import RoomNameModal from "../Components/RoomNameModal";
import { AnimatePresence } from "framer-motion";
import RoomShareModal from "../Components/RoomShareModal";
import toast from "react-hot-toast";
import RoomCardCustomize from "../Components/RoomCardCustomize";
import { rdb } from "../Firebase";
import makeId from "../Helper/makeId";
import { track } from '@amplitude/analytics-browser';
import RoomGameModal from "../Components/RoomGameModal";

export default function Room() {
  let { id }: any = useParams();
  const [cardUser, setCardUser] = useState<any[]>([]);
  const [cardResult, setCardResult] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [name, setName] = useState("guest");
  const [game, setGame] = useState("temp");
  const [condition, setCondition] = useState("initiate");
  const [voteSystem, setVoteSystem] = useState([1, 2, 3]);
  const [cardSettings, setCardSettings] = useState(null);
  const [nameModal, setNameModal] = useState(false);
  const [gameModal, setGameModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [tooltips, setTooltips] = useState(0);
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState([]);
  const myId = localStorage.getItem("thisid") || makeId(15) + Date.now();

  useEffect(() => {
    async function addUser(newName: string) {
      let newuser = {
        id: myId,
        name: newName,
        card: "",
        status: true,
      };
      rdb.ref(`rooms/${id}`).child("users").child(myId).set(newuser);
      localStorage.setItem("name", newName);
      setName(newName);
      const isAdmin = admin.find((i) => i === myId) ? true : false
      track('User Joined', {'userId': myId, 'userName': newName, 'roomId': id, 'isAdmin': isAdmin});
      setNameModal(false);
    }

    async function editCondition(
      condition: string,
      result: any[]
    ): Promise<void> {
      rdb.ref(`rooms/${id}`).update({
        state: condition,
        result: JSON.stringify(result),
      });
      const isAdmin = admin.find((i) => i === myId) ? true : false
      track('Room Update', {'userId': myId, 'userName': name, 'roomId': id, 'state': condition, 'result': result, 'isAdmin': isAdmin});
      setCondition(condition);
    }

    async function editCardSettings(settings: any) {
      settings = JSON.parse(settings);
      track('Edit Card Settings', {'userId': myId, 'userName': name, 'roomId': id, 'settings': settings, 'isAdmin': true});
      rdb.ref(`rooms/${id}`).child("card").set(JSON.stringify(settings));
      setCardSettings(settings);
    }

    async function editNameUser(newestName: string) {
      rdb
        .ref(`rooms/${id}`)
        .child("users")
        .child(myId)
        .child("name")
        .set(newestName);
      localStorage.setItem("name", newestName);
      const isAdmin = admin.find((i) => i === myId) ? true : false
      track('Edit User Name', {'userId': myId, 'userName': newestName, 'roomId': id, 'isAdmin': isAdmin});
      setName(newestName);
      setNameModal(false);
    }

    async function editGameName(newestGameName: string) {
      rdb
        .ref(`rooms/${id}`)
        .child("roomname")
        .set(newestGameName);
      localStorage.setItem("game", newestGameName);
      track('Edit Game Name', {'roomname': newestGameName});
      setGame(newestGameName);
      setGameModal(false);
      console.log(game)
    }

    async function editChoosenCard(card: String | Number): Promise<void> {
      track('Select Card', {'card': card, 'userId': myId, 'userName': name, 'roomId': id});
      rdb.ref(`rooms/${id}`).child("users").child(myId).child("card").set(card);
      setTooltips((prev: number) => {
        if (prev > 0) {
          return prev + 1;
        }
        return 0;
      });
    }

    eventEmitter.on("addUser", addUser);
    eventEmitter.on("editcondition", editCondition);
    eventEmitter.on("editcardsettings", editCardSettings);
    eventEmitter.on("editchoosencard", editChoosenCard);
    eventEmitter.on("editName", editNameUser);
    eventEmitter.on("editGame", editGameName);
    let addvar = false;

    rdb.ref(`rooms/${id}/roomname`).on("value", (snapshot) => {
      setGame(snapshot.val());
    });

    rdb.ref(`rooms/${id}`).on("value", (res) => {
      let data = res.val();
      if (!res.exists() || !res.val()) {
        setError(true);
      } else if (!res.val().server) {
        setError(true);
      } else {
        if (localStorage.getItem("name")) {
          if (!addvar) {
            addUser(localStorage.getItem("name") || "guest");
            addvar = true;
          }
        } else {
          setNameModal(true);
        }
        setLoading(false);
        setError(false);
        // console.log(data);
        setVoteSystem((prev) => {
          if (
            !arraysEqual(
              prev,
              data?.votesystem ? JSON.parse(data?.votesystem) : []
            )
          ) {
            return JSON.parse(data?.votesystem || "[]");
          }
          return prev;
        });
        let users: any[] = [];
        for (let user in data.users) {
          if (
            data.users[user].status &&
            data.users[user].id &&
            data.users[user].name
          ) {
            users.push(data.users[user]);
            if (data.users[user].id === myId) {
              eventEmitter.emit("changecardchoose", data.users[user].card);
            }
          }
        }
        if (localStorage.getItem("name")) {
          rdb
            .ref(`rooms/${id}`)
            .child("users")
            .child(myId)
            .update({ status: true });

          if (!users.find((i) => i.id === myId)) {
            users.push({
              id: myId,
              name: localStorage.getItem("name"),
              card: "",
              status: true,
            });
          }

          rdb
            .ref(`rooms/${id}`)
            .child("users")
            .child(myId)
            .onDisconnect()
            .update({ status: false });
        }
        if (
          users.find((item) => item.card || item.card !== "" || item.card === 0)
        ) {
          eventEmitter.emit("editstatuspick", 1);
        } else {
          eventEmitter.emit("editstatuspick", "");
        }
        users.filter((value, index, self) => {
          return self.indexOf(self.find((i) => i.id === value.id)) === index;
        });
        setAdmin(JSON.parse(data?.admin));
        setCondition(data?.state || "initiate");
        setCardUser(users || []);
        setCardSettings(
          data?.card
            ? JSON.parse(data?.card)
            : {
                color: null,
                logo: null,
              }
        );
        setCardResult(data?.result ? JSON.parse(data?.result) : []);
      }
    });

    const logout = () => {
      // console.log("logout");
      rdb.ref(`rooms/${id}`).off("value");
      if (localStorage.getItem("name")) {
        rdb
          .ref(`rooms/${id}`)
          .child("users")
          .child(myId)
          .update({ status: false });
      }
    };

    window.addEventListener("beforeunload", logout);

    return () => {
      eventEmitter.off("addUser", addUser);
      eventEmitter.off("editcondition", editCondition);
      eventEmitter.off("editcardsettings", editCardSettings);
      eventEmitter.off("editchoosencard", editChoosenCard);
      eventEmitter.off("editName", editNameUser);
      eventEmitter.off("editGame", editGameName);
      window.removeEventListener("beforeunload", logout);
      logout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, myId]);

  useEffect(() => {
    setTooltips((prev: number) => {
      if (prev > 1) {
        return prev + 1;
      }
      if (localStorage.getItem("new")) {
        // console.log("TOOLTIPS");
        localStorage.removeItem("new");
        return prev + 1;
      }
      return 0;
    });
  }, [condition]);

  if (error) {
    return <NotFoundRoom />;
  }

  return (
    <div className="fixed flex flex-col inset-0 w-full h-full text-primary">
      {loading && (
        <div className="absolute w-full h-full inset-0 bg-white z-40 flex items-center justify-center text-3xl font-semibold">
          Loading...
        </div>
      )}
      <AnimatePresence exitBeforeEnter>
        {nameModal && <RoomNameModal closeFunc={() => setNameModal(false)} />}
        {gameModal && <RoomGameModal closeFunc={() => setGameModal(false)} />}
        {shareModal && (
          <RoomShareModal
            closeFunc={(copied: boolean) => {
              const isAdmin = admin.find((i) => i === myId) ? true : false
              track('Invite Players', {'userId': myId, 'userName': name, 'roomId': id, 'isAdmin': isAdmin});
              setShareModal(false);
              if (copied) {
                toast.success("Invitation link copied to clipboard!");
              }
            }}
          />
        )}
        {cardModal && admin.find((i) => i === myId) && (
          <RoomCardCustomize
            cardSettings={cardSettings}
            closeFunc={() => setCardModal(false)}
          />
        )}
      </AnimatePresence>
      <RoomHeader
        editGameFunc={() => setGameModal(true)}
        editNameFunc={() => setNameModal(true)}
        openShareModalFunc={() => setShareModal(true)}
        openCardCustomFunc={() => setCardModal(true)}
        name={name}
        roomname={game}
        tooltips={tooltips}
        isAdmin={admin.find((i) => i === myId) ? true : false}
      />
      <RoomBody
        condition={condition}
        backCardCustom={cardSettings}
        openShareModalFunc={() => setShareModal(true)}
        cardUser={cardUser}
        tooltips={tooltips}
        result={cardResult}
        isAdmin={admin.find((i) => i === myId) ? true : false}
      />
      <RoomFooter
        condition={condition}
        backCardCustom={cardSettings}
        cardList={voteSystem.length > 0 ? voteSystem : [1, 2, 3]}
        tooltips={tooltips}
      />
    </div>
  );
}
