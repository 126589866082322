import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import eventEmitter from "../Helper/eventEmitter";
import RoomTooltips from "./RoomTooltips";

export default function RoomFooter({
  condition,
  cardList = [],
  tooltips,
}: any) {
  const [choose, setChoose] = useState(-1);
  const [bfrChoose, setBfrChoose] = useState(-1);
  const [toolview, setToolview] = useState(true);

  useEffect(() => {
    function changeChooseByValue(val: any) {
      setChoose(cardList.indexOf(val));
    }

    eventEmitter.addListener("changecardchoose", changeChooseByValue);

    return () => {
      eventEmitter.removeListener("changecardchoose", changeChooseByValue);
    };
  }, [cardList]);

  useEffect(() => {
    const changeChoose = (next: boolean = true) => {
      setBfrChoose((prev: any) => {
        if (condition !== "reveal") {
          if (next && prev < cardList.length - 1) {
            return prev + 1;
          }
          if (!next && prev > 0) {
            return prev - 1;
          }
        }

        return prev;
      });
    };

    function listenerfunc(e: any) {
      e = e || window.event;
      if (e.key === "ArrowRight") {
        changeChoose();
      }
      if (e.key === "ArrowLeft") {
        changeChoose(false);
      }
    }

    document.addEventListener("keydown", listenerfunc);

    return () => {
      document.removeEventListener("keydown", listenerfunc);
    };
  }, [cardList, condition]);

  useEffect(() => {
    if (condition !== "reveal") {
      setChoose(-1);
      setBfrChoose(-1);
    }
  }, [condition]);

  // useEffect(() => {
  //   eventEmitter.emit("editchoosencard", cardList[choose] || "");
  //   eventEmitter.emit("editstatuspick", cardList[choose] || "");
  // }, [choose, cardList]);

  useEffect(() => {
    setChoose(bfrChoose);
    eventEmitter.emit("editchoosencard", cardList[bfrChoose] || "");
    eventEmitter.emit("editstatuspick", cardList[bfrChoose] || "");
  }, [bfrChoose, cardList]);

  return (
    <div className="w-full py-5 px-5 relative">
      {tooltips === 2 && toolview && (
        <RoomTooltips type="card" closefunc={() => setToolview(false)} />
      )}
      <div>
        <p className="text-center text-secondary mb-3">
          Choose your card below.
        </p>
      </div>
      <div className="cardcontainer flex w-full md:justify-center overflow-auto py-5 items-center">
        {cardList.map((item: any, index: any) => {
          let choosen = choose === index;
          return (
            <motion.button
              whileTap={{ scale: choosen || condition === "reveal" ? 1 : 0.9 }}
              whileHover={{
                scale: choosen || condition === "reveal" ? 1 : 1.1,
              }}
              whileFocus={{
                scale: choosen || condition === "reveal" ? 1 : 1.1,
              }}
              animate={{
                translateY: choosen ? -10 : 0,
                color: choosen ? "white" : "rgb(29,41,52)",
                backgroundColor: choosen ? "rgb(29,41,52 )" : "white",
              }}
              onClick={() => {
                if (condition !== "reveal") {
                  setBfrChoose(index);
                }
              }}
              key={index}
              className={`carditem carditem${index} border-2 border-primary mx-2 rounded-lg flex h-16 items-center justify-center font-bold text-2xl`}
            >
              <span className="w-card">{item}</span>
            </motion.button>
          );
        })}
      </div>
    </div>
  );
}
