import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
// import Typical from 'react-typical';
import TextTransition, { presets } from "react-text-transition";

function LandingPage() {
  const features = [
    `<strong>Unlimited</strong> games`,
    `<strong>Unlimited</strong> votings per game`,
    `<strong>Unlimited</strong> issues voted per game`,
    `Permanent game urls`,
  ];

  const TEXTS = [
    "engineering managers",
    "product managers",
    "agile development",
  ];
  
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  function renderFeature(item: string, index: number) {
    return (
      <div key={index} className="flex items-center space-x-4 my-1 font-medium">
        <svg
          viewBox="0 0 34 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 sm:w-6 md:w-8 h-3 sm:h-4 md:h-6"
        >
          <path
            d="M10.805 20.513L2.74474 12.428L0 15.1618L10.805 26L34 2.73378L31.2746 0L10.805 20.513Z"
            fill="#24B400"
          />
        </svg>
        <p
          className="text-sm sm:text-base md:text-lg"
          dangerouslySetInnerHTML={{ __html: item }}
        ></p>
      </div>
    );
  }

  return (
    <div className="min-w-screen min-h-screen bg-[#F9F9F9]">
      <div className='bg-[#F9F9F9] mt-4 hideable' style={{position: "absolute", display: 'flex', top:0, width: '100vw', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '2rem'}}>
          <div className="flex space-x-2 items-center">
            <div className="bg-primary w-6 h-8 rounded-md" />
            <p className="text-xl sm:text-2xl text-primary font-montserrat font-bold">
              PokerPlanning.com
            </p>
          </div>
        </div>
        
        <div>
          <Link to="/create-room">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary tracking-wider rounded-md text-sm sm:text-base text-white py-3 w-[80vw] max-w-[175px] font-semibold"
              style={{marginRight: '2rem'}}
            >
              Start new game
            </motion.button>
          </Link>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '2rem', paddingRight: '2rem', width: '100vw'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
        <div className="pt-10 sm:pt-20 text-secondary font-avenir font-medium">
          <div className="text-2xl sm:text-3xl md:text-6xl font-bold font-montserrat text-primary">
            {/* <p>Effort estimation for agile development teams</p> */}
              {/* <Typical
                steps={['for product managers', 1000, 'for engineering managers', 1000, 'for agile development', 1000]}
                loop={Infinity}
                wrapper="p"
              /> */}
              Effort estimation for&nbsp;
              <TextTransition springConfig={presets.stiff} inline direction={"down"}>
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
          </div>
          <p className="mt-8 mb-6 text-sm sm:text-base md:text-lg">
            Simple and engaging tool to make estimates with your team.
          </p>
          <div className="mt-8">
            <Link to="/create-room">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-primary tracking-wider rounded-md text-sm sm:text-base text-white py-3 w-[80vw] max-w-[300px] font-semibold"
              >
                Start new game
              </motion.button>
            </Link>
          </div>
          <div className="mt-8">{features.map(renderFeature)}</div>
          {/* <div className="mt-20 md:mt-40 pb-20">
            <p className="md:text-xl text-primary">TRUSTED BY TEAMS AT</p>
          </div> */}
        </div>
        </div>
        <div className='hideable' style={{display: 'flex', height: '100vh', alignItems: 'center'}}>
          <img className='shadow-2xl' style={{width: '500px', height: 'undefined', aspectRatio: '1.41'}} src={require('../Assets/PokerPlanningExample.png')} alt="Poker Planning Example"/>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
