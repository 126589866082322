import {
  faChevronDown,
  faPlus,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import RoomTooltips from "./RoomTooltips";

export default function RoomHeader({
  name,
  roomname,
  editGameFunc,
  editNameFunc,
  openShareModalFunc,
  openCardCustomFunc,
  tooltips,
  isAdmin,
}: any) {
  const [game, setGame] = useState(false);
  const [menu, setMenu] = useState(false);
  const [toolview, setToolview] = useState(true);

  const gameMenu = [
    {
      title: "Edit game name",
      action: () => {
        setGame(false);
        editGameFunc();
      },
      adminOnly: true,
    },
  ];

  const menuItem = [
    {
      title: "Change name",
      action: () => {
        setMenu(false);
        editNameFunc();
      },
      adminOnly: false,
    },
    {
      title: "Customize card",
      action: () => {
        setMenu(false);
        openCardCustomFunc();
      },
      adminOnly: true,
    },
    {
      title: "Invite players",
      action: () => {
        setMenu(false);
        openShareModalFunc();
      },
      adminOnly: false,
    },
  ];

  useEffect(() => {
    function closemodal(e: any) {
      setMenu(false);
      setGame(false);
    }

    window.addEventListener("click", closemodal);

    return () => {
      window.removeEventListener("click", closemodal);
    };
  }, []);

  return (
    <div className="w-full py-5">
      <div className="mx-auto flex justify-between space-x-2 items-center px-5 md:px-8">
        <div className="flex space-x-2 items-center">
          <div className="bg-primary w-6 h-8 rounded-md" />
          <p className="text-xl sm:text-2xl text-primary font-montserrat font-bold">
            {roomname}
          </p>
          <div className="md:flex space-x-12 hidden">
            <div className="relative">
              <motion.div
                initial={{ scale: 0, translateY: 40 }}
                animate={{ scale: game ? "100%" : 0 }}
                className="absolute origin-top-left flex flex-col top-0 left-0 bg-white rounded-lg w-max px-3 py-5 min-w-[200px] border shadow"
              >
                {gameMenu.map((item: any, index: number) => {
                  if (!item.adminOnly || (item.adminOnly && isAdmin)) {
                    return (
                      <button
                        key={index}
                        onClick={item.action}
                        className="px-2 py-1 text-left hover:opacity-60"
                      >
                        {item.title}
                      </button>
                    );
                  }
                  return null;
                })}
              </motion.div>
              {isAdmin?
              <motion.button
                onClick={(e) => {
                  e.stopPropagation();
                  setGame((prev: boolean) => !prev);
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="text-1xl flex items-center"
              >
                <span className="pt-1 ml-2">
                  <FontAwesomeIcon size="xs" icon={faChevronDown} />
                </span>
              </motion.button>:console.log("not admin")
              }
            </div>
          </div>
        </div>
        <div className="md:flex space-x-12 hidden">
          <div className="relative">
            <motion.div
              initial={{ scale: 0, translateY: 40 }}
              animate={{ scale: menu ? "100%" : 0 }}
              className="absolute origin-top-right flex flex-col top-0 right-0 bg-white rounded-lg w-max px-3 py-5 min-w-[200px] border shadow"
            >
              {menuItem.map((item: any, index: number) => {
                if (!item.adminOnly || (item.adminOnly && isAdmin)) {
                  return (
                    <button
                      key={index}
                      onClick={item.action}
                      className="px-2 py-1 text-left hover:opacity-60"
                    >
                      {item.title}
                    </button>
                  );
                }
                return null;
              })}
            </motion.div>
            <motion.button
              onClick={(e) => {
                e.stopPropagation();
                setMenu((prev: boolean) => !prev);
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="text-1xl flex items-center"
            >
              <span>{name}</span>
              <span className="pt-1 ml-2">
                <FontAwesomeIcon size="xs" icon={faChevronDown} />
              </span>
            </motion.button>
          </div>
          <div className="relative">
            <motion.button
              onClick={openShareModalFunc}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="text-xl px-5 py-2 rounded-md border-2 border-primary"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Invite Players
            </motion.button>
            {tooltips >= 5 && toolview && (
              <RoomTooltips
                type="invite"
                closefunc={() => setToolview(false)}
              />
            )}
          </div>
        </div>
        {/* mobile */}
        <div className="md:hidden">
          <motion.button
            onClick={(e: any) => {
              e.stopPropagation();
              setMenu(true);
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="border rounded-md px-3 py-1"
          >
            <FontAwesomeIcon icon={faBars} />
          </motion.button>
          <motion.div
            initial={{ translateX: "100%" }}
            animate={{ translateX: menu ? 0 : "100%" }}
            className="fixed inset-0 w-full h-full bg-white z-10"
          >
            <div className="flex justify-end p-5">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="text-lg"
              >
                <FontAwesomeIcon icon={faTimes} />
              </motion.button>
            </div>
            <div>
              {menuItem.map((item: any, index: number) => {
                if (!item.adminOnly || (item.adminOnly && isAdmin)) {
                  return (
                    <button
                      key={index}
                      onClick={item.action}
                      className="px-3 py-2 block my-2 w-full text-left"
                    >
                      {item.title}
                    </button>
                  );
                }
                return null;
              })}
              {gameMenu.map((item: any, index: number) => {
                if (!item.adminOnly || (item.adminOnly && isAdmin)) {
                  return (
                    <button
                      key={index}
                      onClick={item.action}
                      className="px-3 py-2 block my-2 w-full text-left"
                    >
                      {item.title}
                    </button>
                  );
                }
                return null;
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
