import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { storage } from "../Firebase";
import eventEmitter from "../Helper/eventEmitter";
import lightOrDark from "../Helper/lightOrDark";

export default function RoomCardCustomize({ closeFunc, cardSettings }: any) {
  const [color, setColor] = useState("#1D2934");
  const [imageUrl, setImageUrl] = useState("");
  const logoUpload = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cardSettings) {
      setColor(cardSettings.color ? cardSettings.color : "#1D2934");
      setImageUrl(cardSettings.logo ? cardSettings.logo : "");
    }
  }, [cardSettings]);

  const submitNewSettings = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      if (logoUpload.current?.files[0]) {
        if (
          logoUpload.current?.files[0].size <= 1024 * 1024 * 2 &&
          logoUpload.current?.files[0].type.toLowerCase().includes("image")
        ) {
          setLoading(true);
          let storageRef = storage.ref();
          let uploadTask = storageRef
            .child(`logo/${Date.now()}-${logoUpload.current.files[0].name}`)
            .put(logoUpload.current.files[0]);
          let loadingtoast = toast.loading("Uploading Image...");
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              eventEmitter.emit(
                "editcardsettings",
                JSON.stringify({ color: color, logo: "" })
              );
              setLoading(false);
              toast.error("Error to Upload", { id: loadingtoast });
              closeFunc();
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // console.log("File available at", downloadURL);
                eventEmitter.emit(
                  "editcardsettings",
                  JSON.stringify({ color: color, logo: downloadURL })
                );
                setLoading(false);
                toast.success("Image uploaded", { id: loadingtoast });
                closeFunc();
              });
            }
          );
        } else {
          toast.error("The file must be image and under 2mb");
        }
      } else {
        eventEmitter.emit(
          "editcardsettings",
          JSON.stringify({ color: color, logo: imageUrl })
        );
        setLoading(false);
        closeFunc();
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-exit
      onClick={(e: any) => {
        if (localStorage.getItem("name") && e?.target?.dataset?.exit) {
          closeFunc();
        }
      }}
      className="fixed top-0 left-0 w-full h-full bg-black/40 z-20"
    >
      <motion.form
        initial={{ opacity: 0, translateY: -100 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: -100 }}
        onSubmit={submitNewSettings}
        className="py-10 md:py-20 px-8 shadow-2xl w-11/12 max-w-2xl bg-white rounded-[48px] mx-auto mt-52"
      >
        <p className="text-black text-2xl font-bold">Customize card back</p>
        <div className="flex pb-12 pt-8">
          <div className="flex-1 flex flex-col space-y-6 py-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full flex items-center justify-center space-x-3 py-3 max-w-[200px] rounded-md bg-secondary text-white relative"
            >
              <input
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="absolute inset-0 block w-full h-full opacity-0 cursor-pointer"
              />
              <motion.span
                animate={{ backgroundColor: color }}
                className="h-6 w-6 rounded-md border-white border"
              />
              <span>Select Color</span>
            </motion.button>
            <motion.button
              type="button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full flex items-center justify-center space-x-3 py-3 max-w-[200px] rounded-md bg-secondary text-white relative"
            >
              <input
                ref={logoUpload}
                className="absolute inset-0 w-full h-full bg-black opacity-0 cursor-pointer"
                type="file"
                accept="image/*"
                onChange={(e: any) => {
                  let tmpfile = URL.createObjectURL(e.target.files[0]);
                  setImageUrl(tmpfile);
                }}
              />
              <div className="h-6 w-6">
                <FontAwesomeIcon className="text-xl" icon={faUpload} />
              </div>
              <span>Upload Logo</span>
            </motion.button>
          </div>
          <div className="flex-1 flex flex-col">
            <div className="flex justify-center">
              <motion.div
                animate={{
                  backgroundColor: color,
                  borderColor: color,
                  color: lightOrDark(color) === "light" ? "#1D2934" : "white",
                }}
                className="border-2 p-1 text-white mx-2 rounded-lg flex h-32 w-max items-center justify-center font-bold text-6xl"
              >
                {imageUrl && imageUrl !== "" ? (
                  <img
                    src={imageUrl}
                    alt=""
                    className="w-24 max-h-full max-w-full"
                  />
                ) : (
                  <span className="w-24 text-center">?</span>
                )}
              </motion.div>
            </div>
            <p className="text-center text-2xl mt-2 text-[#888888]">Preview</p>
          </div>
        </div>
        <motion.button
          type="submit"
          whileHover={{ scale: loading ? 1 : 1.02 }}
          whileTap={{ scale: loading ? 1 : 0.98 }}
          className={`${
            loading ? "opacity-60 cursor-default" : ""
          } rounded-xl text-center block w-full bg-primary text-white py-3`}
        >
          {loading ? "Please wait a minute..." : "Save and continue"}
        </motion.button>
      </motion.form>
    </motion.div>
  );
}
