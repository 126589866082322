import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React from "react";

function RoomTooltips({ type, closefunc }: any) {
  if (type === "invite") {
    return (
      <motion.div className="absolute bottom-[0] translate-y-[120%] right-0 bg-primary text-white rounded-md p-4 w-[300px] opacity-90">
        <button onClick={closefunc} className="absolute top-3 right-3 z-30">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="flex space-x-2 items-center mb-3 pr-20">
          <FontAwesomeIcon color="white" size="2x" icon={faInfoCircle} />
          <p className="font-semibold">Invite Players</p>
        </div>
        <p className="text-sm">
          All set! Share this game's url with your teammates to start voting
        </p>
        <div className="absolute flex items-center justify-center top-0 left-0 w-full">
          <div className="bg-primary w-5 h-5 -translate-y-1/2 rotate-45" />
        </div>
      </motion.div>
    );
  }

  if (type === "show") {
    return (
      <motion.div className="absolute top-[15%] -translate-x-[110%] left-0 bg-primary text-white rounded-md py-4 pl-4 pr-20 max-w-[300px] opacity-90">
        <button onClick={closefunc} className="absolute top-3 right-3 z-30">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="flex space-x-2 items-center mb-3">
          <FontAwesomeIcon color="white" size="2x" icon={faInfoCircle} />
          <p className="font-semibold">Show Cards</p>
        </div>
        <p className="text-sm">
          Once everyone has picked their cards click on "show cards" to see the
          result of voting.
        </p>
        <div className="absolute flex items-center justify-center bottom-0 right-0 h-full">
          <div className="bg-primary w-5 h-5 translate-x-1/2 rotate-45" />
        </div>
      </motion.div>
    );
  }

  if (type === "new") {
    return (
      <motion.div className="absolute top-[15%] translate-x-[110%] right-0 bg-primary text-white rounded-md py-4 pl-4 pr-20 max-w-[300px] opacity-90">
        <button onClick={closefunc} className="absolute top-3 right-3 z-30">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="flex space-x-2 items-center mb-3">
          <FontAwesomeIcon color="white" size="2x" icon={faInfoCircle} />
          <p className="font-semibold">Start new game</p>
        </div>
        <p className="text-sm">
          Click on "start new voting" to reset cards and start a new voting
        </p>
        <div className="absolute flex items-center justify-center bottom-0 left-0 h-full">
          <div className="bg-primary w-5 h-5 -translate-x-1/2 rotate-45" />
        </div>
      </motion.div>
    );
  }

  if (type === "card") {
    return (
      <motion.div className="hidden lg:block absolute top-0 -translate-y-[60%] left-[30%] bg-primary text-white rounded-md py-4 pl-4 pr-20 max-w-[300px] opacity-90">
        <button onClick={closefunc} className="absolute top-3 right-3">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="flex space-x-2 items-center mb-3">
          <FontAwesomeIcon color="white" size="2x" icon={faInfoCircle} />
          <p className="font-semibold">Welcome! Please pick a card</p>
        </div>
        <p className="text-sm">Your card won't be visible to your teammates.</p>
        <div className="absolute flex items-center justify-center bottom-0 left-0 w-full">
          <div className="bg-primary w-5 h-5 translate-y-1/2 rotate-45" />
        </div>
      </motion.div>
    );
  }

  return <div></div>;
}

export default RoomTooltips;
